import React from 'react'
import "./cataloage-content.scss"
import CataloageCategory from './CataloageCategory'
import { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const cataloage = graphql`
{
cataloageBaie:allFile(filter:{relativeDirectory:{eq:"cataloage/Baie"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  cataloageBucatarie:allFile(filter:{relativeDirectory:{eq:"cataloage/Bucatarie"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  cataloageMobila:allFile(filter:{relativeDirectory:{eq:"cataloage/Mobila"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  pardoseli:allFile(filter:{relativeDirectory:{eq:"cataloage/Pardoseli, Gresie si Faianta"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  solutiiTehnice:allFile(filter:{relativeDirectory:{eq:"cataloage/Solutii tehnice"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  cataloageNoi:allFile(filter:{relativeDirectory:{eq:"cataloage/Cataloage2023"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
}
`
const CataloageContent = React.forwardRef(({ }, ref) => {

  const formatCataloageArr = (cataloageArr) => {
    const pdfs = cataloageArr.filter(catalog => catalog.node.extension === 'pdf')
    const images = cataloageArr.filter(catalog => catalog.node.extension === 'png')

    const cataloage = pdfs.map(pdf => {
      const myImage = images.filter(image => image.node.name === pdf.node.name)[0]

      return {
        image: myImage ? myImage.node : { childImageSharp: { fixed: null } },
        pdf: myImage ? pdf.node.publicURL : null,
        primary: myImage ? pdf.node.name : null
      }
    })

    return cataloage
  }
  const { cataloageBaie, cataloageBucatarie, cataloageMobila, pardoseli, solutiiTehnice, cataloageNoi } = useStaticQuery(cataloage)
  const [cataloageArr] = useState(() => {
    const cataloageBaieArr = formatCataloageArr(cataloageBaie.edges)

    const cataloageBucatarieArr = formatCataloageArr(cataloageBucatarie.edges)

    const cataloageMobilaArr = formatCataloageArr(cataloageMobila.edges)

    const cataloagePardoseliArr = formatCataloageArr(pardoseli.edges)


    const cataloageTehniceArr = formatCataloageArr(solutiiTehnice.edges)
    const cataloageNoiArr = formatCataloageArr(cataloageNoi.edges)
    return {
      cataloageBaie: cataloageBaieArr,
      cataloageBucatarie: cataloageBucatarieArr,
      cataloageMobila: cataloageMobilaArr,
      cataloagePardoseli: cataloagePardoseliArr,
      cataloageTehnice: cataloageTehniceArr,
      cataloageNoi: cataloageNoiArr
    }
  })

  return (
    <div ref={ref} className="cataloage-content">
  
      <CataloageCategory cataloage={cataloageArr.cataloageNoi} name="Cataloage noi" id="cataloage-noi" />
    </div>
  )
}
)


export default CataloageContent